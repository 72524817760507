import React, { ReactElement } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  GatsbyMarkdownFile,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import ImageLinkBox from "./image-link-box";

type ContactUsData = {
  readonly file: GatsbyMarkdownFile<{
    readonly buttonText: string;
    readonly summary: string;
    readonly link: string;
    readonly image: FluidSharpImageFile;
  }>;
};

export default function ContactImageLinkBox(): ReactElement {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  } = useStaticQuery<ContactUsData>(graphql`
    {
      file(relativePath: { eq: "call-to-actions/contact-us.md" }) {
        childMarkdownRemark {
          frontmatter {
            title
            summary
            buttonText
            link
            image {
              ...ImageLinkBox_image
            }
          }
        }
      }
    }
  `);

  return (
    <ImageLinkBox
      to={frontmatter.link}
      buttonLabel={frontmatter.buttonText}
      image={frontmatter.image}
    >
      {frontmatter.summary}
    </ImageLinkBox>
  );
}
