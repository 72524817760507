import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import {
  FixedSharpImageFile,
  MarkdownContent,
  FluidSharpImageFile,
  GatsbyMarkdownFile,
} from "@ymcansw-camping/common";
import CommunityLayout from "../../components/community-layout";
import DonateImageLinkBox from "../../components/donate-image-link-box";
import ContactImageLinkBox from "../../components/contact-image-link-box";

type Data = {
  readonly bannerHeaderImage: FixedSharpImageFile;
  readonly pageFile: GatsbyMarkdownFile<{
    readonly title: string;
    readonly image: FluidSharpImageFile;
    readonly programsTitle: string;
    readonly programs?: ReadonlyArray<{ title: string }>;
    readonly bottomContent: string;
    readonly bottomImage: FluidSharpImageFile;
  }>;
};

function CommunityPage() {
  const {
    pageFile: {
      childMarkdownRemark: { rawMarkdownBody, frontmatter },
    },
  } = useStaticQuery<Data>(graphql`
    {
      pageFile: file(relativePath: { eq: "pages/community.md" }) {
        childMarkdownRemark {
          rawMarkdownBody
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 455) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            programsTitle
            programs {
              title
            }
            bottomContent
            bottomImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CommunityLayout
      isIndex
      meta={{
        title: frontmatter.title,
        description:
          "At Camp Yarramundi we are always looking for opportunities to positively engage with people within the community. We do this by offering a range of exclusive Community Engagement Programs.",
      }}
    >
      <div className="content-block">
        <h2>{frontmatter.title}</h2>
        <MarkdownContent>{rawMarkdownBody}</MarkdownContent>
      </div>

      <div className="content-block row">
        <div className="col-xs-12 col-md-5 col-md-push-7">
          <div className="image-container">
            <Img fluid={frontmatter.image.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-xs-12 col-md-7  col-md-pull-5">
          <h6 className="mt0">{frontmatter.programsTitle}</h6>
          <ul>
            {frontmatter.programs?.map((program) => (
              <li key={program.title}>{program.title}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="content-block with-border-top" />

      <div className="content-block row">
        <div className="col-xs-12 col-md-4">
          <div className="image-container">
            <Img fluid={frontmatter.bottomImage.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-xs-12 col-md-8">
          <MarkdownContent>{frontmatter.bottomContent}</MarkdownContent>
        </div>
      </div>

      <div className="content-block with-border-top image-link-box-container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ContactImageLinkBox />
          </div>
          <div className="col-xs-12 col-sm-6">
            <DonateImageLinkBox />
          </div>
        </div>
      </div>
    </CommunityLayout>
  );
}

export default CommunityPage;
