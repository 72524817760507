import React, { ReactElement, ReactText } from "react";
import {
  ArrowLink,
  InternalOrExternalLink,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import Img from "gatsby-image";

type ImageLinkBoxProps = {
  readonly to: string;
  readonly buttonLabel: string;
  readonly image: FluidSharpImageFile;
  readonly children: ReactText | ReadonlyArray<ReactText>;
};

export default function ImageLinkBox({
  to,
  buttonLabel,
  image,
  children,
}: ImageLinkBoxProps): ReactElement {
  return (
    <div className="image-link-box">
      <InternalOrExternalLink to={to}>
        <Img fluid={image.childImageSharp.fluid} alt={buttonLabel} />
      </InternalOrExternalLink>
      <h3 className="h7 mt2">{children}</h3>
      <ArrowLink to={to}>{buttonLabel}</ArrowLink>
    </div>
  );
}

export const query = graphql`
  fragment ImageLinkBox_image on File {
    childImageSharp {
      fluid(maxWidth: 379, maxHeight: 162) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
